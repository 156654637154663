import { Box, Card } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { PropsWithChildren } from 'react';

interface WidgetProps extends PropsWithChildren {
  card?: boolean;
}

export const Widget = (props: WidgetProps) => {
  const Container: OverridableComponent<any> = props.card ? Card : Box;
  return (
    <Container sx={{ backgroundColor: 'white', borderRadius: '4px', p: 2, overflow: 'auto' }}>
      {props.children}
    </Container>
  );
};
