import { Identity } from '@/model/identity';
import * as Sentry from '@sentry/react';
import pkg from '../../../../package.json';
import { ApiError } from '../api';
import { Config, Env } from '../config';

const SCRUB_KEYS = [
  'addressLine1',
  'addressLine2',
  'city',
  'state',
  'zip',
  'familyName',
  'givenName',
  'firstmiddleName',
  'lastName',
  'gender',
  'ssn',
  'birthDate',
  'birthDay',
  'contact',
  'contacts',
  'telecom',
  'contactDetail',
  'username',
  'password',
  'email',
  'phone',
  'value',
];

const IGNORED_ERROR_REGEX_STRINGS = ['Wrong email or password'];
const GROUPED_ERROR_MESSAGES = ['POST /lookup/dataValueSet 500 - 404 Not found: Table'];

interface ExtendedXMLHttpRequest extends XMLHttpRequest {
  __sentry_xhr_body?: any;
}

const recursivelyScrubKeys = (data: { [key: string]: any } | any[], scrubKeys: string[]) => {
  if (!data) {
    return;
  } else if (Array.isArray(data)) {
    for (let element of data) {
      if (typeof element === 'object') {
        recursivelyScrubKeys(element, scrubKeys);
      }
    }
  } else if (typeof data === 'object') {
    for (const key of Object.keys(data)) {
      if (typeof data[key] === 'object') {
        recursivelyScrubKeys(data[key], scrubKeys);
      } else if (scrubKeys.includes(key.toLowerCase())) {
        data[key] = '***';
      }
    }
  }
};

const patchXMLHttpRequest = () => {
  const originalSend = XMLHttpRequest.prototype.send;
  XMLHttpRequest.prototype.send = function (this: ExtendedXMLHttpRequest, payload: any) {
    this.__sentry_xhr_body = payload;
    return originalSend.call(this, payload);
  };
};

const isBlackListed = (sentryEvent: Sentry.Event) => {
  return Boolean(
    sentryEvent.exception?.values?.some(
      ({ value }) =>
        !!value &&
        IGNORED_ERROR_REGEX_STRINGS.some((regexString) => {
          const regex = new RegExp(regexString, 'i');
          return regex.test(value);
        })
    )
  );
};

export class SentryService {
  config: Config;

  constructor(config: Config) {
    this.config = config;
    if (!this.config.sentry.enabled) return;
    this.initiate();
  }

  initiate() {
    patchXMLHttpRequest();
    Sentry.init({
      dsn: this.config.sentry.dsn,
      environment: this.config.env,
      release: pkg.version,
      integrations: [
        new Sentry.BrowserTracing({
          tracePropagationTargets: ['localhost', /^.*wncty.app.*$/],
        }),
        new Sentry.Replay(),
      ],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      normalizeDepth: 10,
      beforeBreadcrumb(breadcrumb, hint) {
        if (breadcrumb.category === 'xhr') {
          const xhr = hint?.xhr;
          if (xhr) {
            const requestBody = JSON.parse(xhr.__sentry_xhr_body);
            const responseBody = JSON.parse(xhr.responseText);
            const requestHeaders = xhr.__sentry_xhr_v2__['request_headers'];

            breadcrumb.data = {
              ...breadcrumb.data,
              request_headers: requestHeaders,
              response_body: responseBody,
              request_body: requestBody,
            };
            recursivelyScrubKeys(
              breadcrumb.data,
              SCRUB_KEYS.map((s) => s.toLowerCase())
            );
          }
        }

        return breadcrumb;
      },
      beforeSend(event, hint) {
        if (isBlackListed(event)) {
          return null;
        }
        const isDevOrLocal = event.environment === Env.Local || event.environment === Env.Dev;
        const isApiError = hint?.originalException instanceof ApiError;
        const errorMessage = event?.exception?.values?.[0]?.value;

        if (isApiError && isDevOrLocal && errorMessage?.toLowerCase().includes('stub')) {
          return null;
        }

        const groupedErrorMessage = GROUPED_ERROR_MESSAGES.find((message) => errorMessage?.includes(message));
        if (groupedErrorMessage) {
          event.fingerprint = [groupedErrorMessage];
        }

        if (event.breadcrumbs) {
          event.breadcrumbs.forEach((breadcrumb) => {
            if (breadcrumb.data) {
              recursivelyScrubKeys(
                breadcrumb.data,
                SCRUB_KEYS.map((s) => s.toLowerCase())
              );
            }
          });
        }

        return event;
      },
    });
  }

  setUser(user: Identity) {
    if (!this.config.sentry.enabled) return;
    Sentry.setUser({
      id: user.id,
      username: `${user.first_name ? user.first_name[0] : ''}${user.last_name ? user.last_name[0] : ''}`,
    });
  }

  logError(error: Error) {
    if (!this.config.sentry.enabled) return;
    Sentry.captureException(error, (scope) => {
      scope.setTransactionName(error.message);
      scope.setFingerprint([error.message]);
      return scope;
    });
  }
}
