import { useServices } from '@/core/services';
import { IdentityMock, identities } from '@/core/services/api/mock/mock-data/identities';
import { useGetPath } from '@/navigation';
import { Avatar, Box, Button, Card, Chip, Grid, Typography } from '@mui/material';
import { LabelValuePair } from '@wnc/ui/src/components/common';
import { useFormatValue } from '@wnc/ui/src/core/hooks';
import { useNavigate } from 'react-router-dom';

export const Demo = () => {
  const services = useServices();
  const navigate = useNavigate();
  const getPath = useGetPath();
  const format = useFormatValue();

  const onLoadDemo = (identity: IdentityMock) => {
    if (identity.internal.type === 'Offline') {
      services.queryClient.clear();
      localStorage.setItem('token', identity.internal.token);
      services.config.authApi.useMock = true;
      services.authApi.restart(services.config.authApi, services.queryClient, services.sentry);
      navigate(getPath('Clients'));
    }
  };

  const onGoToLogin = (identity: IdentityMock) => {
    services.queryClient.clear();
    localStorage.removeItem('token');

    if (identity.internal.type === 'Offline') {
      services.config.authApi.useMock = true;
      services.authApi.restart(services.config.authApi, services.queryClient, services.sentry);
    } else {
      services.config.authApi.useMock = false;
      services.authApi.restart(services.config.authApi, services.queryClient, services.sentry);
    }

    window.location.href = '/auth/login';
  };

  return (
    <Box data-testid='demo-section'>
      <Typography variant='h2'>Demo Data</Typography>
      <Typography variant='h3' sx={{ mt: 4 }}>
        Demo Users
      </Typography>
      <Grid container spacing={2} sx={{ mt: 2, marginLeft: '-16px !important', marginRight: '-16px !important' }}>
        {identities.map((identity) => (
          <Grid item xs={12} sm={4} xl={3} key={identity.id}>
            <Card sx={{ p: 2 }} data-testid={`identity-${identity.id}-card`}>
              <Box display='flex' sx={{ mb: 3 }}>
                <Chip
                  label={format(identity.internal.type)}
                  color={identity.internal.type === 'Live' ? 'success' : 'warning'}
                />
              </Box>
              <Box display='flex' flexDirection='row' alignItems='center' data-testid='identity-name-section'>
                <Avatar alt='User Picture' src={identity.pictureUrl} sx={{ mr: 1 }} />
                <Typography variant='h5' fontWeight='fontWeightMedium' sx={{ flexGrow: 1 }}>
                  {identity.first_name} {identity.last_name}
                </Typography>
                <Box data-testid='identity-email-password-section'>
                  <LabelValuePair label='Email' value={identity.email_address} />
                  <LabelValuePair label='Password' value={identity.internal.password} />
                </Box>
              </Box>
              <Button
                fullWidth
                sx={{ mt: 3 }}
                onClick={onLoadDemo.bind(this, identity)}
                disabled={identity.internal.type !== 'Offline'}
                data-testid='load-demo-button'
              >
                Load Demo
              </Button>
              <Button
                variant='text'
                size='small'
                fullWidth
                sx={{ mt: 1 }}
                onClick={onGoToLogin.bind(this, identity)}
                data-testid='go-log-in-button'
              >
                Go to Log In Page
              </Button>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
