import { generatePath } from 'react-router-dom';
import { createRoutes } from './routes';
import { CustomRouteObject } from './types';

const Paths: Record<string, string> = {};

const computeRoutePaths = (routes: CustomRouteObject[], parentPath = '') => {
  routes.forEach((route) => {
    let path = route.path && route.path !== '*' ? `${parentPath}/${route.path}` : parentPath;
    if (route.id) {
      Paths[route.id] = path || '/';
    }
    if (route.children) {
      computeRoutePaths(route.children, path);
    }
  });
};

computeRoutePaths(createRoutes({ excludeInternalRoutes: false }));

export const useGetPath = () => {
  return (pathKey: string, urlParams?: Record<string, string | number | null | undefined>) => {
    if (!Paths[pathKey]) {
      throw new Error(`Path with key "${pathKey}" not found`);
    }

    const requiredParams = Paths[pathKey].match(/:[a-zA-Z]+/g)?.map((param) => param.replace(':', ''));

    let fullParams = {};

    if (requiredParams) {
      requiredParams.forEach((param) => {
        if (!urlParams || !Object.keys(urlParams).includes(param)) throw new Error(`Missing required param "${param}"`);
        fullParams = { ...fullParams, [param]: urlParams[param] || 'undefined' };
      });
    }

    return generatePath(Paths[pathKey], fullParams);
  };
};
