import { Config, Env } from './types';

export const config: Config = {
  env: Env.Uat,
  authUrl: 'https://wncty.app/auth',
  pmiUrl: 'https://wncty.app/pmi',
  pmmUrl: 'https://wncty.app/pmm',
  pmiApi: {
    baseUrl: 'https://pmi-api.wncty.app',
    timeout: 60000,
    useMock: false,
  },
  pmmApi: {
    baseUrl: 'https://pmm-api.wncty.app',
    timeout: 60000,
    useMock: false,
  },
  authApi: {
    baseUrl: 'https://pmi-api.wncty.app',
    timeout: 60000,
    useMock: false,
  },
  authApi2: {
    baseUrl: 'https://wnc-auth-api-dot-wncty-ptfm-app-uat-42806.uk.r.appspot.com',
    timeout: 60000,
    useMock: false,
  },
  urls: {
    termsOfUse: 'https://wellnecity.com/terms-of-use/',
    privacyPolicy: 'https://wellnecity.com/privacy-policy/',
    contact: 'https://wellnecity.com/contact/',
  },
  sentry: {
    enabled: true,
    dsn: 'https://5ce7ca70eb2799bbdac7211b7905dfa7@o4506100468350976.ingest.sentry.io/4506107035189248',
  },
  session: {
    inactivityTimeoutMinutes: 15,
    inactivityWarningDurationMinutes: 2,
  },
  wellnecityOrgId: 'org-002',
  features: {
    clientSideMemberList: {
      enabled: false,
    },
    removeSavingsCategory: {
      enabled: true,
    },
    membersOfInterest: {
      enabled: true,
    },
    reassignInteractions: {
      enabled: true,
    },
    pmmClientSupportingOrg: {
      enabled: true,
    },
    pmmClientInternalTeams: {
      enabled: true,
    },
    pmmOrganizationForm: {
      enabled: true,
    },
    pmmOrganizationLinks: {
      enabled: false,
    },
    pmmOrganizationInternalTeams: {
      enabled: false,
    },
    pmmClientLegalContracts: {
      enabled: false,
    },
    pmmInternalContactActions: {
      enabled: false,
    },
    pmmOrganizationActivate: {
      enabled: false,
    },
    pmmOrganizationNotes: {
      enabled: false,
    },
    financialControls: {
      enabled: true,
    },
    pmmAccessAndPermissions: {
      enabled: true,
    },
    pmmClientReportingTab: {
      enabled: true,
    },
  },
};
