import { PractitionerUser } from '@wnc/ui/src/model';

export const practitioners: PractitionerUser[] = [
  {
    id: 'user-001',
    emailAddress: 't.tham+chc@wellnecity.com',
    firstName: 'Tom',
    lastName: 'Tham',
    practitionerId: 'user-001',
  },
  {
    id: '650440605268d137805c3fe5',
    emailAddress: 'a.gondaliya@wellnecity.com',
    firstName: 'ashish',
    lastName: 'gondaliya',
    practitionerId: '64f80591fc8c76c0b14c09c2',
  },
  {
    id: '650a85857067d9b6b06f9e8b',
    emailAddress: 'a.patelchc@gmail.com',
    firstName: 'amit',
    lastName: 'patel',
    practitionerId: 'f94543ab-6bde-4be4-a656-b4b8787ad53a',
  },
  {
    id: '650a993658bd431dfe3bcdeb',
    emailAddress: 'a.patelnewchc20092023@gmail.com',
    firstName: 'amit',
    lastName: 'patel',
    practitionerId: 'f94543ab-6bde-4be4-a656-b4b8787ad53a',
  },
  {
    id: '650bd1e1d962e3a130555067',
    emailAddress: 'a.patel+uat09@gmail.com',
    firstName: 'amit',
    lastName: 'patel',
    practitionerId: 'f94543ab-6bde-4be4-a656-b4b8787ad53a',
  },
  {
    id: '650bd543d935da55f13d50e4',
    emailAddress: 'a.patel+uat0921@gmail.com',
    firstName: 'amituat',
    lastName: 'patel',
    practitionerId: 'f94543ab-6bde-4be4-a656-b4b8787ad53a',
  },
  {
    id: '650bdcec7e1904370f6deb12',
    emailAddress: 'a.patel+uat0921test@gmail.com',
    firstName: 'amittest',
    lastName: 'patel',
    practitionerId: 'f94543ab-6bde-4be4-a656-b4b8787ad53a',
  },
  {
    id: '650be7bc63ad2d91bf7664fb',
    emailAddress: 'a.patel+well0921test@gmail.com',
    firstName: 'amittest',
    lastName: 'patel',
    practitionerId: 'f94543ab-6bde-4be4-a656-b4b8787ad53a',
  },
  {
    id: '650be7d863ad2d91bf7664fc',
    emailAddress: 'a.gondaliya+wellencity09@gmail.com',
    firstName: 'ashish',
    lastName: 'gondaliya',
    practitionerId: 'f94543ab-6bde-4be4-a656-b4b8787ad53a',
  },
  {
    id: '650be7fdeddb15054e531777',
    emailAddress: 'a.gondaliya+dev@gmail.com',
    firstName: 'ashish',
    lastName: 'gondaliya',
    practitionerId: 'f94543ab-6bde-4be4-a656-b4b8787ad53a',
  },
];
