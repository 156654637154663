import { Chip, ChipProps } from '@mui/material';

export interface StatusChipProps {
  label?: string | null;
  statusSet?: 'clinicalStatus' | 'verificationStatus' | 'medicationStatus' | 'general';
  statusSchema?: Record<string, Color>;
  size?: ChipProps['size'];
  variant?: ChipProps['variant'];
}

type Color = 'info' | 'warning' | 'success' | 'default' | 'primary' | 'secondary' | 'error' | undefined;

const statusColors: Record<string, Record<string, Color>> = {
  general: {
    default: undefined,
  },
};

export const StatusChip = (props: StatusChipProps) => {
  const { label, statusSet = 'general', variant = 'filledSoft', size = 'medium' } = props;
  if (!label) return null;
  const statusSchema = props.statusSchema || statusColors[statusSet];
  const color = statusSchema[label.toLowerCase()] || statusSchema.default;
  return (
    <Chip variant={variant} size={size} label={label || undefined} color={color} data-testid={`status-chip-${label}`} />
  );
};
