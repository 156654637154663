import { Stack, Typography, TypographyProps } from '@mui/material';
import { useFormatValue } from '@wnc/ui/src/core/hooks';
import { EvaluatedSchemaField } from '@wnc/ui/src/model';
import { join } from 'lodash';
import { useMemo } from 'react';
import { StatusChip } from './status-chip';

type Variant = 'summary' | 'summaryLarge' | 'defaultLight' | 'defaultRegular' | 'cardRegular' | 'cardLight';

interface ValueProps extends EvaluatedSchemaField<any> {
  variant?: Variant;
  emptyPlaceholder?: string;
  commaSeparated?: boolean;
}

const textStyles: { [key in Variant]: TypographyProps } = {
  summary: {
    variant: 'h5',
    fontWeight: 'fontWeightMedium',
    color: 'text.primary',
  },
  summaryLarge: {
    variant: 'h2',
    color: 'text.primary',
  },
  defaultLight: {
    variant: 'body2',
    fontWeight: 'fontWeightLight',
  },
  defaultRegular: {
    variant: 'body2',
    fontWeight: 'fontWeightRegular',
    color: 'application.body.main',
  },
  cardRegular: {
    variant: 'body2',
    fontWeight: 'fontWeightRegular',
    lineHeight: '1rem',
  },
  cardLight: {
    variant: 'body3',
    fontWeight: 'fontWeightLight',
    lineHeight: '0.875rem',
  },
};

export const Value = (props: ValueProps) => {
  const { value, type, variant, emptyPlaceholder, label, commaSeparated = false, ...rest } = props;
  const formatValue = useFormatValue();
  const formattedValue = useMemo(() => {
    const vArray = value instanceof Array ? value : [value];
    return vArray.length
      ? commaSeparated
        ? [
            join(
              vArray.map((v: unknown) => formatValue(v, type)),
              ', '
            ).replace(/,\s*$/, ''),
          ]
        : vArray.map((v: unknown) => formatValue(v, type))
      : [emptyPlaceholder || ''];
  }, [value, commaSeparated, emptyPlaceholder, formatValue, type]);

  if (type === 'status') {
    return (
      <Stack spacing={0.5} sx={{ width: 'fit-content' }} data-testid={`value-for-label-${label}`}>
        {formattedValue.map((v, i) => (
          <StatusChip key={i} {...rest} label={v || emptyPlaceholder || ''} />
        ))}
      </Stack>
    );
  } else if (variant) {
    return (
      <Stack spacing={0} data-testid={`value-for-label-${label}`}>
        {formattedValue.map((v, i) => (
          <Typography key={i} {...textStyles[variant]}>
            {v || emptyPlaceholder || ''}
          </Typography>
        ))}
      </Stack>
    );
  } else {
    return <></>;
  }
};
