import { Button, Stack, TextField, Typography } from '@mui/material';
import { TableauEmbed } from '@stoddabr/react-tableau-embed-live';
import { Widget } from '@wnc/ui/src/components/common';
import { useState } from 'react';

export const TableauPage = () => {
  const [url, setUrl] = useState('');
  const [token, setToken] = useState('');
  const [userUrl, setUserUrl] = useState('');
  const [userToken, setUserToken] = useState('');

  const onSubmit = () => {
    setUserToken(token);
    setUserUrl(url);
  };

  return (
    <Widget>
      <Stack
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
        minHeight={'100vh'}
      >
        <Typography variant='h4'>Tableau Demo</Typography>
        <Stack mb={2} mt={2}>
          {!userUrl ? (
            <Stack gap={2} mt={3}>
              <TextField
                label='Tableau URL'
                placeholder='Enter Workbook URL'
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
              <TextField
                label='Token'
                placeholder='Enter Tableau Token'
                value={token}
                onChange={(e) => setToken(e.target.value)}
              />
              <Button variant='contained' onClick={onSubmit}>
                Submit
              </Button>
            </Stack>
          ) : (
            <TableauEmbed sourceUrl={userUrl} token={userToken} toolbar='top' />
          )}
        </Stack>
      </Stack>
    </Widget>
  );
};
