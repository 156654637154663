import { useGetPath } from '@/navigation';
import { AppBar, Box, Button, Toolbar, Typography } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';

interface Page {
  name: string;
  routeId: string;
}

const pages: Page[] = [
  {
    name: 'Tableau',
    routeId: 'TableauDemoIndex',
  },
];

export const ExternalDemoLayout = () => {
  const navigate = useNavigate();
  const getPath = useGetPath();
  const goTo = (routeId: string) => navigate(getPath(routeId));

  return (
    <Box sx={{ backgroundColor: 'application.background.main', minHeight: '100vh' }}>
      <AppBar position='static'>
        <Toolbar data-testid='demo-toolbar'>
          <Box display='flex' flexDirection='row' alignItems='center'>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 52,
                mr: 2,
              }}
              data-testid='demo-tab'
            >
              <Typography variant='h3'>Demo</Typography>
            </Box>
            {pages.map((page) => (
              <Button
                key={page.name}
                onClick={goTo.bind(this, page.routeId)}
                sx={{ my: 2, display: 'block' }}
                variant='text'
                size='large'
                data-testid={`${page.name}-tab`}
              >
                {page.name}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Box mt={4} mx={4}>
        <Outlet />
      </Box>
    </Box>
  );
};
