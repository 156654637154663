export enum Env {
  Local = 'local',
  Dev = 'dev',
  Uat = 'uat',
  Prod = 'prod',
}

export interface ApiConfig {
  baseUrl: string;
  timeout: number;
  useMock: boolean;
}

export interface Config {
  env: Env;
  authUrl: string;
  pmiUrl: string;
  pmmUrl: string;
  pmiApi: ApiConfig;
  pmmApi: ApiConfig;
  authApi: ApiConfig;
  authApi2: ApiConfig;
  wellnecityOrgId: string;
  urls: {
    termsOfUse: string;
    privacyPolicy: string;
    contact: string;
  };
  sentry: {
    enabled: boolean;
    dsn: string;
  };
  session: {
    inactivityTimeoutMinutes: number;
    inactivityWarningDurationMinutes: number;
  };
  features: {
    clientSideMemberList: {
      enabled: boolean;
    };
    removeSavingsCategory: {
      enabled: boolean;
    };
    membersOfInterest: {
      enabled: boolean;
    };
    reassignInteractions: {
      enabled: boolean;
    };
    pmmClientSupportingOrg: {
      enabled: boolean;
    };
    pmmOrganizationForm: {
      enabled: boolean;
    };
    pmmClientInternalTeams: {
      enabled: boolean;
    };
    pmmInternalContactActions: {
      enabled: boolean;
    };
    pmmOrganizationLinks: {
      enabled: boolean;
    };
    pmmClientLegalContracts: {
      enabled: boolean;
    };
    pmmOrganizationActivate: {
      enabled: boolean;
    };
    pmmOrganizationInternalTeams: {
      enabled: boolean;
    };
    pmmOrganizationNotes: {
      enabled: boolean;
    };
    financialControls: {
      enabled: boolean;
    };
    pmmAccessAndPermissions: {
      enabled: boolean;
    };
    pmmClientReportingTab: {
      enabled: boolean;
    };
  };
}

export type SentryConfig = Config['sentry'];
