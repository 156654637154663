import { Identity } from '@/model';
import { QueryClient } from '@tanstack/react-query';
import { ApiConfig, SentryService } from '@wnc/ui/src/core/services';
import { addMiddleware } from '@wnc/ui/src/core/services/api/middleware/index';
import axios, { AxiosInstance } from 'axios';
import { pmiApiUrls } from './pmi-api-urls';
import { ApiLoginResponse, ApiResponse, ForgotPasswordPayload, LoginRequest, ResetPasswordPayload } from './types';

class AuthApiClient {
  private axiosInstance!: AxiosInstance;

  constructor(config: ApiConfig, queryClient: QueryClient, sentry: SentryService) {
    this.start(config, queryClient, sentry);
  }

  start(config: ApiConfig, queryClient: QueryClient, sentry: SentryService) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.axiosInstance = axios.create({
      baseURL: config.baseUrl,
      timeout: config.timeout,
      headers: { timeZone },
    });

    if (config.useMock) {
      require('./mock/mock').createMock(this.axiosInstance);
    }

    addMiddleware(this.axiosInstance, config, queryClient, sentry);
  }

  restart(config: ApiConfig, queryClient: QueryClient, sentry: SentryService) {
    this.start(config, queryClient, sentry);
  }

  login(data: LoginRequest) {
    const { url, method } = pmiApiUrls.login;
    return this.axiosInstance.request<ApiLoginResponse>({
      url,
      method,
      data,
    });
  }

  forgotPassword(data: ForgotPasswordPayload) {
    const { url, method } = pmiApiUrls.forgotPassword;
    return this.axiosInstance.request<ApiResponse<{}>>({
      url,
      method,
      data,
    });
  }

  resetPassword(data: ResetPasswordPayload) {
    const { url, method } = pmiApiUrls.resetPassword;
    return this.axiosInstance.request<ApiResponse<{}>>({
      url,
      method,
      data,
    });
  }

  logout() {
    const { url, method } = pmiApiUrls.logout;
    return this.axiosInstance.request<ApiResponse<{}>>({
      url,
      method,
    });
  }

  getIdentity() {
    const { url, method } = pmiApiUrls.getIdentity;
    return this.axiosInstance.request<ApiResponse<Identity>>({
      url,
      method,
    });
  }
}

export default AuthApiClient;
