import { InternalLayout } from '@/components/internal-layout';
import { Demo } from '@/pages/internal/demo';
import { Navigate } from 'react-router-dom';
import { CustomRouteObject } from '../types';

export const internalRoutes: CustomRouteObject[] = [
  {
    id: 'Internal',
    path: 'internal',
    title: 'Internal',
    element: <InternalLayout />,
    children: [
      {
        id: 'InternalIndex',
        index: true,
        element: <Navigate replace to='demo' />,
      },
      {
        id: 'Demo',
        path: 'demo',
        element: <Demo />,
        title: 'Demo',
      },
    ],
  },
];
