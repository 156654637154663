import { useServices } from '@/core/services';
import { Env } from '@wnc/ui/src/core/services';
import { useMemo } from 'react';
import { RouterProvider as ReactRouterProvider } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';
import { createRoutes } from './routes';

export const RouterProvider = () => {
  const { config } = useServices();
  const routes = useMemo(() => createRoutes({ excludeInternalRoutes: config.env === Env.Prod }), [config.env]);
  const router = useMemo(() => createBrowserRouter(routes, { basename: process.env.PUBLIC_URL }), [routes]);
  return <ReactRouterProvider router={router} />;
};
