import wellnecityLogo from '@/assets/logos/wellnecity-purple-logo.svg';
import { useGetPath } from '@/navigation';
import { AppBar, Box, Button, Toolbar } from '@mui/material';
import { Outlet, useNavigate } from 'react-router';
interface Page {
  name: string;
  routeId: string;
}

const pages: Page[] = [
  {
    name: 'Demo',
    routeId: 'Demo',
  },
];

export const InternalLayout = () => {
  const navigate = useNavigate();
  const getPath = useGetPath();
  const goTo = (routeId: string) => navigate(getPath(routeId));

  return (
    <Box sx={{ backgroundColor: 'application.background.main', minHeight: '100vh' }}>
      <AppBar position='static'>
        <Toolbar>
          <Box display='flex' flexDirection='row' alignItems='center'>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 52,
                mr: 2,
              }}
            >
              <img src={wellnecityLogo} alt='Wellnecity' style={{ height: 32 }} />
            </Box>
            {pages.map((page) => (
              <Button
                key={page.name}
                onClick={goTo.bind(this, page.routeId)}
                sx={{ my: 2, display: 'block' }}
                variant='text'
                size='large'
              >
                {page.name}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Box mt={4} mx={4}>
        <Outlet />
      </Box>
    </Box>
  );
};
