import { BasePerson, MultiUnitValue, MultiUnitValue2, SchemaFieldType, SimpleCode, Status } from '@wnc/ui/src/model';
import dayjs, { Dayjs } from 'dayjs';

export const formatValue = (
  value: unknown,
  format: SchemaFieldType = 'string',
  emptyPlaceholder: string = 'N/A'
): string => {
  let formattedValue;

  if (value !== null && value !== undefined) {
    switch (format) {
      case 'number':
        formattedValue = value === 0 ? '0' : (value as number).toLocaleString('en-US');
        break;
      case 'currency':
        formattedValue = (value as number).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });
        break;
      case 'bigCurrency':
        formattedValue = (value as number).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          notation: 'compact',
        });
        break;
      case 'date':
        formattedValue = dayjs(value as string | Dayjs).isValid()
          ? dayjs(value as string | Dayjs).format('MM/DD/YYYY')
          : (value as string);
        break;
      case 'datetime':
        formattedValue = dayjs(value as string | Dayjs).isValid()
          ? dayjs(value as string | Dayjs).format('MM/DD/YYYY h:mm A')
          : (value as string);
        break;
      case 'isoDate':
        formattedValue = dayjs(value as string | Dayjs).isValid()
          ? dayjs(value as string | Dayjs).format()
          : (value as string);
        break;
      case 'boolean':
        formattedValue = value === true ? 'Yes' : value === false ? 'No' : null;
        break;
      case 'status':
        formattedValue = (value as Status).display;
        break;
      case 'multiUnit':
        formattedValue = formatMultiUnitValue(value as MultiUnitValue);
        break;
      case 'multiUnit2':
        formattedValue = formatMultiUnit2Value(value as MultiUnitValue2, false);
        break;
      case 'isoMultiUnit2':
        formattedValue = formatMultiUnit2Value(value as MultiUnitValue2, true);
        break;
      case 'user':
        formattedValue = `${(value as BasePerson).firstName + ' ' || ''}${(value as BasePerson).lastName}`;
        break;
      case 'simpleCode':
        formattedValue = (value as SimpleCode).display;
        break;
      default:
        formattedValue = String(value);
        break;
    }
  }

  return formattedValue || emptyPlaceholder || '';
};

const formatMultiUnitValue = (v?: MultiUnitValue | null): string | null => {
  if (!v) return null;
  if (v.age) return String(v.age);
  if (v.period) return `${dayjs(v.period.start).format('MM/DD/YYYY')} to ${dayjs(v.period.end).format('MM/DD/YYYY')}`;
  if (v.range) return `${v.range.low} to ${v.range.high} (${v.range.units})`;
  if (v.quantity) return `${v.quantity.value} (${v.quantity.units})`;
  if (v.string) return v.string;
  if (v.dateTime) return dayjs(v.dateTime).format('MM/DD/YYYY');
  return null;
};

const formatMultiUnit2Value = (v?: MultiUnitValue2 | null, isIso?: boolean): string | null => {
  if (v === null || v === undefined) return null;
  if (v.quantity) return `${v.quantity.value?.toLocaleString() || ''} (${v.quantity.unit || ''})`;
  if (v.range)
    return v.range.low?.value?.toLocaleString() || v.range.high?.value?.toLocaleString()
      ? `${v.range.low?.value?.toLocaleString() || ''} to ${v.range.high?.value?.toLocaleString() || ''}`
      : null;
  if (v.boolean !== null && v.boolean !== undefined)
    return v.boolean === true ? 'Yes' : v.boolean === false ? 'No' : null;
  if (v.string) return v.string;
  if (v.integer !== null && v.integer !== undefined) return String(v.integer);
  if (v.ratio)
    return `${v.ratio.numerator?.value?.toLocaleString() || ''}/${v.ratio.denominator?.value?.toLocaleString() || ''}`;
  // TODO Support dateTime, period and age
  if (v.dateTime) return isIso ? dayjs(v.dateTime).format() : dayjs(v.dateTime).format('MM/DD/YYYY h:mmA');
  if (v.period)
    return `${v.period.start ? dayjs(v.period.start).format(isIso ? undefined : 'MM/DD/YYYY') : ''} to ${
      v.period.end ? dayjs(v.period.end).format(isIso ? undefined : 'MM/DD/YYYY') : ''
    }`;
  if (v.age) return String(v.age.value || '');
  return null;
};
