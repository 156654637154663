import { Chip, Stack, Typography, TypographyProps } from '@mui/material';
import { EvaluatedSchemaField } from '@wnc/ui/src/model';
import { Value } from './value';

type Variant = 'summary' | 'summaryLarge' | 'defaultLight' | 'defaultRegular' | 'cardRegular' | 'cardLight';

const textStyles: { [key in Variant]: TypographyProps<'div', {}> } = {
  summary: {
    variant: 'body2',
    fontWeight: 'fontWWeightRegular',
    color: 'text.primary',
  },
  summaryLarge: {
    variant: 'body2',
    fontWeight: 'fontWeightBold',
    color: 'text.primary',
  },
  defaultLight: {
    variant: 'body2',
    fontWeight: 'fontWeightMedium',
    color: 'application.body.light',
  },
  defaultRegular: {
    variant: 'body2',
    fontWeight: 'fontWeightBold',
    color: 'application.body.light',
  },
  cardRegular: {
    variant: 'body3',
    fontWeight: 'fontWeightLight',
    color: 'application.body.lighter',
    lineHeight: '0.875rem',
  },
  cardLight: {
    variant: 'body3',
    fontWeight: 'fontWeightLight',
    color: 'application.body.lighter',
    lineHeight: '0.875rem',
  },
};

export interface LabelValuePairProps extends EvaluatedSchemaField<any> {
  variant?: Variant;
  autoHide?: boolean;
  emptyPlaceholder?: string;
  flexWrap?: 'wrap-reverse' | 'nowrap' | 'wrap';
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  chipTitle?: boolean;
  chipColor?: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  chipLabel?: React.ReactNode;
  mt?: number | string;
  px?: number;
  spacing?: number;
  chipSize?: 'small' | 'medium';
  commaSeparated?: boolean;
  removeColons?: boolean;
}

export const LabelValuePair = (props: LabelValuePairProps) => {
  const {
    variant = 'defaultRegular',
    direction = 'row',
    autoHide = false,
    chipTitle = false,
    chipColor,
    chipLabel,
    px = 3,
    mt = '8px',
    spacing = 0.5,
    chipSize,
    flexWrap = 'nowrap',
    removeColons = false,
    ...field
  } = props;

  if (autoHide && !field.value) return null;

  if (variant === 'summary' || variant === 'summaryLarge') {
    return (
      <Stack spacing={0} alignItems='center' px={variant === 'summaryLarge' ? px : 0}>
        <Value {...field} variant={variant} />
        <Typography {...textStyles[variant]} data-testid='label'>
          {field.label}
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack spacing={spacing} direction={direction} flexWrap={flexWrap} marginTop={mt}>
      <Typography {...textStyles[variant]} component='div' data-testid='label'>
        {field.label}
        {!removeColons && ':'}
        {chipTitle && <Chip label={chipLabel} variant='filledSoft' color={chipColor} size={chipSize} />}
      </Typography>

      <Value {...field} variant={variant} />
    </Stack>
  );
};
